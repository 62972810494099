<template>
    <div :class="custom_classname ? custom_classname: 'img-stimuli'" :style="style_string"></div>
</template>

<script>
    export default {
        name: "BasicStimuliImage",
        props: ["image_url", "custom_classname", "background_color"],
        data: function() {
            return {
              style_string:`background-image:url(${this.image_url}); `
            }
        },
        mounted: function() {
            if(this.background_color !== undefined) {
              this.style_string += `background-color: ${this.background_color}`
            }
        }
    }
</script>

<style scoped>
    .img-stimuli {
        margin: auto;
        width: 300px;
        height: 300px;
        background-size: 300px 300px;
        background-repeat: no-repeat;
    }
    .img-stimuli-4-3 {
        margin: auto;
        width: 300px;
        height:225px;
        background-size: 300px 225px;
        background-repeat: no-repeat;
    }

    .img-stimuli-delayed-phase {
        margin: auto;
        width: 540px;
        height: 360px;
        background-size: 540px 360px;
        background-repeat: no-repeat;
    }

    .img-stimuli-sm {
      margin: auto;
      width: 150px;
      height: 150px;
      background-size: 150px 150px;
      background-repeat: no-repeat;
    }
</style>