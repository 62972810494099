<template>
    <div>
        <RightAlignButton :button_text="'OK'" :click_callback="submission"></RightAlignButton>
        <BasicError :error="error" :error_msg="error_msg"></BasicError>
        <div class="justify-content-center text-center my-2">
            <p>{{ gettext(`Do you remember where this object was located?`)}}</p>
            <div>
                <BasicStimuliImage :image_url="object_img_url" :background_color="object_img_background_color"></BasicStimuliImage>
            </div>
        </div>
        <div class="d-flex justify-content-center my-2">
            <BasicCardInput :display_text="gettext('Yes')"
                            :name="'user_memory'"
                            :actual_val="'yes'"
                            :model_val="user_memory"
                            v-model="user_memory"
                            v-on:updateError="updateError">
            </BasicCardInput>
            <BasicCardInput :display_text="gettext('No')"
                            :name="'user_memory'"
                            :actual_val="'no'"
                            :model_val="user_memory"
                            v-model="user_memory"
                            v-on:updateError="updateError">
            </BasicCardInput>

            <BasicCardInput :display_text="gettext('Not Sure')"
                            :name="'user_memory'"
                            :actual_val="'not_sure'"
                            :model_val="user_memory"
                            v-model="user_memory"
                            v-on:updateError="updateError">
            </BasicCardInput>
        </div>
    </div>
</template>

<script>
    import RightAlignButton from "./RightAlignButton";
    import BasicCardInput from "./BasicCardInput";

    import BasicStimuliImage from "./BasicStimuliImage";
    import BasicError from "./BasicError";

    export default {
        name: "RoomMetaMemory",
        components: {BasicError, BasicStimuliImage, BasicCardInput, RightAlignButton},
        props: ["object_img_url", "object_img_background_color"],
        data: function() {
            return {
                user_memory: null,
                error: false,
                error_msg: ""
            }
        },
        methods: {
            updateError: function(event) {
                this.error = false;
            },

            submission: function(event) {
                if(this.user_memory !== null) {
                    this.$emit("submission", this.user_memory);
                }
                else {
                    this.error = true;
                    // Add gettext here so that it is recognized when running makemessages
                    this.error_msg = this.gettext("Please select one of the options below");
                }

            }
        }
    }
</script>

<style scoped>

</style>