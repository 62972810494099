<template>
    <div>
        <template v-if="step !== 4">
            <p class="max-width-750 mx-auto d-non d-sm-block d-lg-none"><strong><u>{{ gettext("Make sure you scroll down to see all question options!") }}</u></strong></p>
        </template>
        <template v-if="step === 0">
            <ImageSelectionList :question_list="question_obj.stimuli[question_num]"
                                :question_text="gettext('In which room did you see this object?')"
                                :button_text="'OK'"
                                :question_img="question_obj.target_image"
                                :question_background_color="question_obj.target_image_background_color"
                                :item_classname="'img-stimuli-delayed-phase'"
                                :has_background="false"
                                v-on:submission="selectionSub">
            </ImageSelectionList>
        </template>

        <template v-else-if="step === 1">
            <RoomMetaMemory :object_img_url="question_obj.target_image" :object_img_background_color="question_obj.target_image_background_color" v-on:submission="metaMemorySub"></RoomMetaMemory>
        </template>


        <template v-else-if="step === 2">
            <RoomConfidence :num_choices="5" v-on:submission="confidenceSub"></RoomConfidence>
        </template>
        <template v-else-if="step === 3">
            <RightAlignButton :button_text="gettext('OK')" :click_callback="incrementStep"></RightAlignButton>
            <div class="justify-content-center text-center">
                <p class="my-2">{{ gettext("Where was this object located?") }}</p>
                <div>
                    <BasicStimuliImage :background_color="question_obj.target_image_background_color" :image_url="question_obj.target_image" class="my-2"></BasicStimuliImage>
                </div>
                <p class="my-2">{{gettext("Tap OK, and then tap where the object was located in the room")}}</p>
            </div>
        </template>

        <template v-else-if="step === 4">
            <ImagePlacement :after_image_url="selected_room.url"
                            :place_image_url="question_obj.target_image"
                            :place_image_width="question_obj.target_image_width"
                            :target_coords="target_coords"
                            v-on:getcoords="getCoords"
                            v-on:submission="placementSub"></ImagePlacement>
        </template>

    </div>
    
</template>

<script>
    import ImagePlacement from "./ImagePlacement";
    import ImageSelectionList from "./ImageSelectionList";
    import RoomConfidence from "./RoomConfidence";
    import RoomMetaMemory from "./RoomMetaMemory";
    import BasicStimuliImage from "./BasicStimuliImage";
    import RightAlignButton from "./RightAlignButton";
    export default {
        name: "DelayedQuestion",
        components: {
            RightAlignButton,
            BasicStimuliImage, RoomMetaMemory, RoomConfidence, ImageSelectionList, ImagePlacement},
        props: ["question_obj"],
        data: function() {
            return {
                step: 0,
                question_num: 0,
                max_questions: 0,
                selected_room: {},
                user_memory: null,
                confidence_level: null,
                placement_x_coord: -1,
                placement_y_coord: -1,
                placement_time: 0,
                placement_submission_time: 0,
                target_coords: {
                    target_x: 0,
                    target_y: 0,
                    correct_radius: 50,
                },
                // Following the format in case there are multiple rounds per question in the future.
                placed_correct: null,
                placement_distance: -1,
                placement_meta_memory: null,
            }
        },
        mounted: function() {
            // Currently not used but this.question_obj.stimuli is an array of arrays containing stimuli objects.
            // The delayed phase only needs an Array of stimuli objects since it has a linear progression and not multiple image selection pieces per question
            // This has been added in case this changes in the future.
            this.max_questions = this.question_obj.stimuli.length;
            this.target_coords.target_x = this.question_obj.target_x_coordinate;
            this.target_coords.target_y = this.question_obj.target_y_coordinate;
        },

        methods: {
            incrementStep: function(event) {
                this.step++;
            },
            decrementStep: function(event) {
                this.step--;
            },
            incrementRound: function(event) {
                this.question_num++;
                if(this.question_num >= this.max_questions) {
                    this.step++;
                }
            },

            selectionSub: function(room, select_count, select_time, sub_time) {
                let currSelection = this.question_obj.stimuli[this.question_num][room];
                currSelection.num_changed = select_count;
                currSelection.select_time = select_time;
                currSelection.sub_time = sub_time;
                currSelection.total_time = select_time + sub_time;
                this.selected_room = currSelection;

                // this.incrementRound
                this.incrementStep();
            },

            metaMemorySub(mem_select) {
                this.user_memory = mem_select;
                this.incrementStep();
            },

            confidenceSub: function(conf_level) {
                this.confidence_level = conf_level;
                this.incrementStep();
            },

            getCoords: function(x, y, place_time) {
                this.placement_x_coord = x;
                this.placement_y_coord = y;
                this.placement_time = place_time;
            },

            placementSub: function(sub_time, placed_correct, placement_distance) {
                this.placement_submission_time = sub_time;
                // Here we say it is placed correct only if they selected the right room as well as the right location.
                if(this.selected_room.is_correct) {
                    this.placed_correct = placed_correct;
                    this.placement_distance = placement_distance
                }
                else {
                    this.placed_correct = false;
                }
                this.checkPlacementMeta();
                this.$emit('submission', this.createResult())
            },

            checkPlacementMeta: function() {
                // User placed the object correctly and believed they knew where it was, or User place the object incorrectly and believed to not know where it was or was unsure
                this.placement_meta_memory = ((this.placed_correct && (this.user_memory === 'yes')) || (!this.placed_correct && (this.user_memory !== 'yes')));
            },

            createResult: function(event) {
                return {
                    id: this.question_obj.id,
                    name: this.question_obj.name,
                    delayed_selection: this.selected_room,
                    placement: {
                        x_coord: this.placement_x_coord,
                        y_coord: this.placement_y_coord,
                        is_correct: this.placed_correct,
                        distance: this.placement_distance,
                        meta_correct: this.placement_meta_memory,
                        place_time: this.placement_time,
                        sub_time: this.placement_submission_time,
                        total_time: this.placement_time + this.placement_submission_time,
                    }
                }
            },
        } // End Methods
    } // End Export
</script>

<style scoped>

</style>