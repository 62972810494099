<template>
    <div class="container align-content-center">
      <template v-if="!display_bottom_button">
          <RightAlignButton :button_text="'OK'" :click_callback="button_click"></RightAlignButton>
      </template>
        <h2 v-if="title_text">{{gettext(title_text)}}</h2>

        <div :class="(!second_text && ! mobile_text && !mobile_text_special && !bold_text) ? 'my-lg-3 my-sm-1 d-flex pl-sm-2 pl-lg-0' : 'my-lg-3 my-sm-1 pl-sm-2 pl-lg-0'">
            <p :class="left_text ? 'text-left mx-auto px-lg-0 max-width-850': 'max-width-850 mx-auto'">{{ gettext(instruction_text) }}</p>
            <p v-if="second_text" :class="left_text ? 'text-left mx-auto max-width-850': 'mx-auto max-width-850'">{{ gettext(second_text) }}</p>
            <p v-if="mobile_text" :class="left_text ? 'mx-auto d-sm-block d-lg-none text-left max-width-850': 'mx-auto d-sm-block d-lg-none max-width-850'">{{ gettext(mobile_text) }}</p>
            <p v-if="mobile_text_special" :class="left_text ? 'mx-auto d-sm-block d-lg-none text-left max-width-850' : 'mx-auto d-sm-block d-lg-none max-width-850'"><strong><u>{{ gettext(mobile_text_special) }}</u></strong></p>
            <p v-if="bold_text" :class="left_text ? 'text-red mx-auto text-left max-width-850' : 'text-red mx-auto max-width-850'"><strong>{{ gettext(bold_text) }}</strong></p>
        </div>
        <template v-if="display_bottom_button">
          <RightAlignButton :button_text="'OK'" :click_callback="button_click"></RightAlignButton>
        </template>
    </div>
</template>

<script>
    import RightAlignButton from "./RightAlignButton";
    export default {
        components: {RightAlignButton},
        props: {
            instruction_text: String,
            bold_text: String,
            mobile_text: String,
            mobile_text_special: String,
            title_text: String,
            display_bottom_button: Boolean,
            left_text: Boolean,
            second_text: String,
        },
        methods: {
            button_click: function() {
                this.$emit('next');
            },
        }
    }
</script>

<style scoped>
.max-width-750 {
    max-width: 750px;
}
.max-width-850 {
  max-width: 850px;
}

.text-red{
    color: darkred;
}
</style>