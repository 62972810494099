<template>
    <div class="container p-3" id="series-container">
        <template v-if="step === 0">
            <BasicInstructions :instruction_text="gettext('This app will show you a series of 4 and 6 images of objects. After the cross, the app will ask you whether you have seen an object. After replying, you will see another cross and you can forget everything before starting with the next group of objects. Do your best as fast as possible.')"
                               :bold_text="gettext('Do not use the back button as it will restart the test.')"
                               v-on:next="incrementStep">
            </BasicInstructions>
        </template>

        <template v-else-if="step === 1">
            <SeriesQuestion :key="question_num"
                            :question_num="question_num"
                            :question_total="max_questions"
                            :time_interval="json_data.display_seconds * 1000"
                            :question_obj="json_data.questions[question_num]"
                            :stimuli_separator="stimuli_separator"
                            v-on:submission="nextQuestion">
            </SeriesQuestion>
        </template>
    </div>
</template>

<script>
    import BasicInstructions from "./BasicInstructions";
    import SeriesQuestion from "./SeriesQuestion";

    import "whatwg-fetch"

    export default {
        name: "SeriesDriver",
        components: {SeriesQuestion, BasicInstructions},
        props: ["json_data"],
        mounted: function() {
            this.max_questions = this.json_data.questions.length;
            this.test_id = this.json_data.id;
            this.test_type = this.json_data.test_type;
        },

        data: function() {
            return {
                step: 0,
                test_id: null,
                test_type: null,
                question_num: 0,
                max_questions: 0,
                user_responses: [],
                stimuli_separator: "/static/img/fixation_cross.jpg",
                complete: false,
                groups_obj: {},
                averages: {},
            }
        },

        methods: {
            incrementStep: function(event) {
                this.step++;
                if(this.step >= 2) {
                    // Need to let the data update from the "submit" emit to avoid race conditions, "complete" will resubmit with an updated flag.
                    this.$nextTick(() => {
                      this.$emit("complete");
                    })

                }
            },
            decrementStep: function(event) {
                this.step--;
            },

            nextQuestion: function(results) {
                this.user_responses.push(results);
                this.question_num++;
                let submit_emit_type = "SERIES_NEXT_QUESTION_SUBMIT"
                if(this.question_num >= this.max_questions) {
                    this.getGroups();
                    this.calculateAvgs();
                    this.complete = true;
                    this.incrementStep();
                    submit_emit_type = "SERIES_DRIVER_COMPLETE_SUBMIT"
                }
                // Emit the submission on every question for partial data collection
                this.$emit("submission", this.createResult(), submit_emit_type, this.complete);
            },

            createResult: function(event) {
                return {
                    test_id: this.test_id,
                    test_type: this.test_type,
                    is_complete: this.complete,
                    user_results: this.user_responses,
                    groups_obj: this.groups_obj,
                    averages: this.averages,
                }
            },

            getGroups: function() {
                for(let response of this.user_responses) {
                    // If this group already exists
                    let group_name = `${response.group}_${response.stimuli_length}`;
                    if(group_name in this.groups_obj) {
                        this.groups_obj[group_name].num_total++;
                        this.groups_obj[group_name].cum_response_time += response.response_time;
                        if(response.is_correct) {
                            this.groups_obj[group_name].num_correct++;
                            this.groups_obj[group_name].cum_response_time_corr += response.response_time;
                        }
                        else {
                            this.groups_obj[group_name].cum_response_time_wrong += response.response_time;
                        }
                    } // Otherwise make the group
                    else {
                        this.groups_obj[group_name] = {
                            num_total: 1,
                            num_correct: response.is_correct ? 1 : 0,
                            cum_response_time: response.response_time,
                            cum_response_time_corr: response.is_correct ? response.response_time : 0,
                            cum_response_time_wrong: response.is_correct ? 0 : response.response_time,
                        }
                    }
                }
            },

            calculateAvgs: function() {
                for(let group in this.groups_obj) {
                    let num_wrong = this.groups_obj[group].num_total - this.groups_obj[group].num_wrong;
                    this.averages[group] = {
                        num_correct: this.groups_obj[group].num_correct,
                        num_total: this.groups_obj[group].num_total,
                        avg_rt: this.groups_obj[group].cum_response_time / this.groups_obj[group].num_total,
                        avg_corr_rt: this.groups_obj[group].cum_response_time_corr / (this.groups_obj[group].num_correct > 0 ? this.groups_obj[group].num_correct : 1),
                        avg_wrong_rt: this.groups_obj[group].cum_response_time_wrong / (num_wrong > 0 ? num_wrong : 1),
                    }
                }
            }

        }
    }
</script>

<style scoped>
    #series-container {
        color: white;
    }
</style>