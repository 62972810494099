<template>
    <div>
        <RightAlignButton :button_text="'OK'" :click_callback="submission"></RightAlignButton>
        <BasicError :error="error" :error_msg="error_msg"></BasicError>
        <div class="justify-content-center text-center my-2">
            <p v-html="display_msg_do"></p>
        </div>
        <div class="d-flex align-items-stretch justify-content-center my-2">
            <BasicCardInput v-for="index in num_choices" :key="index"
                            :display_text="index"
                            :name="'user_memory_num'"
                            :actual_val="index"
                            :model_val="confidence_level"
                            :top_text="index === 1 ? gettext('Not Confident'): index === num_choices ? gettext('Very Confident'): ''"
                            v-model.number="confidence_level"
                            v-on:updateError="updateError">
            </BasicCardInput>
        </div>
    </div>
</template>

<script>
    import RightAlignButton from "./RightAlignButton";
    import BasicCardInput from "./BasicCardInput";

    import BasicError from "./BasicError";

    export default {
        name: "RoomConfidence",
        components: {BasicError, BasicCardInput, RightAlignButton},
        props: ["num_choices"],
        data: function() {
            return {
                confidence_level: null,
                error: false,
                error_msg: "",
                display_msg_do: this.gettext(`How confident are you that you <u><b>do</b></u> remember its location?`),
            }
        },

        methods: {
            updateError: function(event) {
                this.error = false;
            },

            submission: function(event) {
                if(this.confidence_level !== null) {
                    this.$emit('submission', this.confidence_level);
                }
                else {
                    this.error = true;
                    // Add gettext here so that it is recognized when running makemessages
                    this.error_msg = this.gettext("Please select one of the options below");
                }
            }
        }


    }
</script>

<style scoped>

</style>