<template>
    <div class="p-3">
        <h4>{{timeRemainingMinutes}}:{{(10 > timeRemainingSeconds) ? "0": ""}}{{timeRemainingSeconds}}</h4>
    </div>
</template>

<script>
    export default {
        components: {},
        props: ["timeMinutes"],
        mounted: function () {
          this.timeRemaining = this.timeMinutes * 60
          this.timeRemainingMinutes = this.timeMinutes
        },

        data: function() {
              return {
                  // timeRemaining is in seconds
                  timeRemaining: 0,
                  timeRemainingMinutes: 0,
                  timeRemainingSeconds: 0,
              }
          },
        watch: {
          timeRemaining: {
            // Every second update the time remaining
            handler(value) {
              if(value > 0) {
                setTimeout(() => {
                  this.timeRemaining--
                  if(this.timeRemaining === 0) {
                    this.$emit("complete")
                  }
                  this.timeRemainingSeconds = this.timeRemaining % 60
                  if (this.timeRemainingSeconds === 59) {
                    this.timeRemainingMinutes--
                  }
                }, 1000)
              }
            },
            immediate: true // This ensures the watcher is triggered upon creation
          }
        },
    }
</script>