<template>
    <div class="">
        <template v-if="step !== 0 && step !== 5">
            <p class="max-width-750 mx-auto d-non d-sm-block d-lg-none"><strong><u>{{ gettext("Make sure you scroll down to see all question options!") }}</u></strong></p>
        </template>
        <template v-if="step === 0">
            <div class="">
                <img class="mw-100" :src="json_data.room_image_before">
            </div>
        </template>

        <template v-else-if="step === 1">
            <ImageSelectionList :question_list="json_data.stimuli[question_num]"
                                :question_text="gettext('Which object did you see?')"
                                :button_text="gettext('OK')"
                                :key="question_num"
                                :has_background="true"
                                v-on:submission="selectionSub">
            </ImageSelectionList>
        </template>

        <template v-else-if="step === 2">
            <RoomMetaMemory :object_img_url="json_data.target_image" :object_img_background_color="json_data.target_image_background_color" v-on:submission="metaMemorySub"></RoomMetaMemory>
        </template>

        <template v-else-if="step === 3">
            <RoomConfidence :num_choices="5" v-on:submission="confidenceSub"></RoomConfidence>
        </template>

        <template v-else-if="step === 4">
            <RightAlignButton :button_text="gettext('OK')" :click_callback="incrementStep"></RightAlignButton>
            <div class="justify-content-center text-center">
                <p class="my-2">{{ gettext("Where was this object located?") }}</p>
                <div>
                    <BasicStimuliImage :background_color="json_data.target_image_background_color" :image_url="json_data.target_image" class="my-2"></BasicStimuliImage>
                </div>
                <p class="my-2">{{gettext("Tap OK, and then tap where the object was located in the room")}}</p>
            </div>
        </template>

        <template v-else-if="step === 5">
            <ImagePlacement :after_image_url="json_data.room_image_after"
                            :place_image_url="json_data.target_image"
                            :place_image_width="json_data.target_image_width"
                            :target_coords="target_coords"
                            v-on:getcoords="getCoords"
                            v-on:submission="placementSub">
            </ImagePlacement>
        </template>
    </div>
</template>


<script>
    import ImageSelectionList from "./ImageSelectionList";
    import RightAlignButton from "./RightAlignButton";
    import ImagePlacement from "./ImagePlacement";
    import RoomMetaMemory from "./RoomMetaMemory";
    import RoomConfidence from "./RoomConfidence";
    import BasicStimuliImage from "./BasicStimuliImage";

    import timing from "../timing";

    export default {
        name: "RoomQuestion",
        mixins: [timing],
        props: ["json_data", "display_duration"],
        components: {
            BasicStimuliImage,
            RoomConfidence,
            RoomMetaMemory,
            ImageSelectionList, ImagePlacement, RightAlignButton},
        data: function() {
            // This should get json data from the server per room
            // For now return static data
            return {
                max_questions: 0,
                step: 0,
                question_num: 0,
                selection_count: 0,
                selected_image: null,
                selected_image_list: [],
                selection_count_list: [],
                target_coords: {
                    target_x: 0,
                    target_y: 0,
                    correct_radius: 50,
                },
                // User memory is the response to question: Do you remember where this object was located?
                user_memory: null,
                confidence_level: null,
                time_start: 0,
                time_end: 0,
                selection_time: 0,
                placement_timing: null,
                placement_submission_timing: null,
                placement_total_time: null,
                place_x_coord: -1,
                place_y_coord: -1,
                placed_correct: null,
                placement_distance: -1,
                // placement_meta_memory is determine by correctness of placement compared to user_memory
                placement_meta_memory: null,
            } // End Return
        }, // End Data
        // Avoid using arrow functions for vue component methods as it will have 'this' scoped lexically and will not be the Vue Component.
        mounted: function() {
            this.max_questions = this.json_data.stimuli.length;
            this.target_coords.target_x = this.json_data.target_x_coordinate;
            this.target_coords.target_y = this.json_data.target_y_coordinate;
            this.preloadImages();
            setTimeout(() => this.incrementStep(), this.display_duration);
        },
        methods: {
            incrementStep: function(event) {
                this.step++;
                this.recordStartTime();
            },

            decrementStep: function(event) {
                this.step--;
            },

            incrementRound: function(event) {
                this.question_num++;
                if (this.question_num >= this.max_questions) {
                    this.incrementStep();
                }
            },

            decrementRound: function(event){
                this.question_num--;
            },

            preloadImages: function(event) {
                const imageBefore = new Image();
                const imageAfter = new Image();
                const imageTarget = new Image();
                imageBefore.src = this.json_data.room_image_before;
                imageAfter.src = this.json_data.room_image_after;
                imageTarget.src = this.json_data.target_image;
                for(let stim_list of this.json_data.stimuli) {
                    for(let stim of stim_list) {
                        const image = new Image();
                        image.src = stim.url;
                    }
                }
            },

            selectionChange: function(event) {
                // Record the end time on a selection change
                // This will be the time it takes a user to pick a selection
                this.recordEndTime();
                this.selection_time = this.getTimeDiff();
                this.selection_count++;
            },

            selectionSub: function(selection, selection_cnt, selection_time, submission_time) {
                // Record the info
                let chosenAnswer = this.json_data.stimuli[this.question_num][selection];
                chosenAnswer.num_changed = selection_cnt;
                chosenAnswer.select_time = selection_time;
                chosenAnswer.sub_time = submission_time;
                chosenAnswer.total_time = selection_time+submission_time;
                this.selected_image_list.push(chosenAnswer);

                // Automatically display the next set of questions
                // This will increment the step automatically once we reach the end of the questions array
                this.incrementRound();
                //console.log(`Count: ${selection_cnt}, SelectTime: ${selection_time}, SubTime: ${submission_time}`);
            },

            metaMemorySub: function(mem_select) {
                this.user_memory = mem_select;
                this.incrementStep();
            },

            confidenceSub: function(conf_level) {
                this.confidence_level = conf_level;
                this.incrementStep();
            },

            getCoords: function(coord_x, coord_y, click_time) {
                this.place_x_coord = coord_x;
                this.place_y_coord = coord_y;
                this.placement_timing = click_time;
            },

            placementSub: function(submission_time, placed_correct, placement_distance) {
                this.placement_submission_timing = submission_time;
                this.placed_correct = placed_correct;
                this.placement_distance = placement_distance;
                this.checkPlacementMeta();
                this.$emit("submission", this.createResult());
                //console.log(`Placement: ${this.placement_timing}`);
                //console.log(`Submission: ${this.placement_submission_timing}`);
                //console.log(`X: ${this.place_x_coord} Y: ${this.place_y_coord}`);
            },

            checkPlacementMeta: function() {
                // User placed the object correctly and believed they knew where it was, or User place the object incorrectly and believed to not know where it was
                this.placement_meta_memory = ((this.placed_correct && (this.user_memory === 'yes')) || (!this.placed_correct && (this.user_memory !== 'yes')))
            },

            createResult: function(event) {
                return {
                    id: this.json_data.id,
                    name: this.json_data.name,
                    memory_selections: this.selected_image_list,
                    meta_memory: this.user_memory,
                    meta_confidence: this.confidence_level,
                    placement: {
                        x_coord: this.place_x_coord,
                        y_coord: this.place_y_coord,
                        is_correct: this.placed_correct,
                        distance: this.placement_distance,
                        is_meta_correct: this.placement_meta_memory,
                        place_time: this.placement_timing,
                        sub_time: this.placement_submission_timing,
                        total_time: this.placement_timing + this.placement_submission_timing
                    },
                }
            },

        } // End Methods
    } // End Export
</script>

<style scoped>

</style>