<template>
    <div>
        <template v-if="question_num < max_questions">
            <template v-if="step === 0">
                <RoomQuestion :json_data="json_data[question_num]"
                              :display_duration="display_duration"
                              :key="question_num"
                              v-on:submission="nextQuestion"></RoomQuestion>
            </template>
            <template v-else>
                <BasicInstructions :instruction_text="gettext('Press OK to start the next question.')"
                                   v-on:next="decrementStep"></BasicInstructions>
            </template>
        </template>
    </div>
</template>

<script>
    import RoomQuestion from "./RoomQuestion";
    import BasicInstructions from "./BasicInstructions";

    export default {
        name: "MemoryPhase",
        components: {BasicInstructions, RoomQuestion},
        props: ["json_data", "display_duration"],

        mounted() {
            this.max_questions = this.json_data.length;

        },
        data: function() {
            return {
                step: 0,
                question_num: 0,
                max_questions: 0,
                user_responses: [],
            }
        },

        methods: {
            incrementStep: function() {
                this.step++;
            },
            decrementStep: function() {
                this.step--;
            },

            nextQuestion: function(question_data) {
                this.user_responses.push(question_data);
                this.incrementStep();
                this.question_num++;

                // Post the results per metaMemory question
                this.$emit('submission', this.createResults());
                if(this.question_num >= this.max_questions) {
                    // Move on to the delayed phase
                    this.$emit('complete')
                }
            },

            createResults: function() {
                return {
                    user_responses: this.user_responses,
                }
            }
        }

    }
</script>

<style scoped>

</style>