<template>
    <div class="px-sm-1 px-lg-3 pt-lg-3 pt-sm-1">
        <template v-if="step > 0 && step < 10">
          <div class="d-flex justify-content-start align-items-center position-fixed">
            <div class="chip chip-green d-flex">
                <p class="m-0">{{gettext("Test")}}</p>
            </div>
          </div>
        </template>
        <template v-if="step === 0">
          <FNameInstructions :display_countdown_bar="display_countdown_bar" :displayDuration="display_duration" v-on:complete="incrementStep" :is_mobile="is_mobile"></FNameInstructions>
        </template>

        <template v-else-if="step === 1">
            <FnameLearningPhase :questions="learning_json_data"
                                :displayDuration="display_duration"
                                :display_countdown_bar="display_countdown_bar"
                                v-on:complete="incrementStep"
                                v-on:submission="handleLearningSubmission"
                                :is_mobile="is_mobile"
            ></FnameLearningPhase>
        </template>

        <template v-else-if="step === 2">
            <FnameRecognitionPhase :questions="recognition_json_data"
                                   v-on:complete="incrementStep"
                                   v-on:submission="handleRecognitionSubmission"
                                   :is_mobile="is_mobile"
            ></FnameRecognitionPhase>
        </template>
        <template v-else-if="step === 3">
          <FNameInitialPhase :questions="initial_json_data"
                              v-on:complete="incrementStep"
                              v-on:submission="handleInitialsSubmission"
                             :is_mobile="is_mobile"
          ></FNameInitialPhase>
        </template>
        <template v-else-if="step === 4">
          <FnameMatchingPhase :questions="matching_json_data"
                              v-on:complete="incrementStep"
                              v-on:submission="handleMatchingSubmission"
                              :is_mobile="is_mobile"
          ></FnameMatchingPhase>
        </template>

        <template v-else-if="step === 5">
          <div class="my-4">
            <p class="font-weight-bold">{{ gettext("Please do not close this window.") }}</p>
            <p>{{ gettext("Your results have been recorded! The rest of this test will begin in: ") }}</p>
            <CountdownTimer v-on:complete=incrementStep :timeMinutes="json_data.display_wait_interval"></CountdownTimer>
          </div>
        </template>
        <template v-else-if="step===6">
          <div class="my-4">
            <BasicInstructions :instruction_text="gettext('A few minutes ago you saw several faces and their names. Please select the faces you saw before.')"
                               v-on:next="incrementStep"
                               :display_bottom_button="true"
            ></BasicInstructions>
          </div>
        </template>
        <template v-else-if="step === 7">
          <FnameRecognitionPhase :questions="recognition_json_data"
                                  v-on:complete="incrementStep"
                                  v-on:submission="handleRecognitionDelayedSubmission"
                                  :is_mobile="is_mobile"
          ></FnameRecognitionPhase>
        </template>
        <template v-else-if="step === 8">
          <FNameInitialPhase :questions="initial_json_data_delayed"
                              v-on:complete="incrementStep"
                              v-on:submission="handleInitialsDelayedSubmission"
                             :is_mobile="is_mobile"
          ></FNameInitialPhase>

        </template>
        <template v-else-if="step === 9">
          <FnameMatchingPhase :questions="matching_json_data_delayed"
                              v-on:complete="incrementStep"
                              v-on:submission="handleMatchingDelayedSubmission"
                              :is_mobile="is_mobile"
          ></FnameMatchingPhase>
        </template>
        <template v-else>
          <div class="my-4">
            <p>{{ gettext(this.end_message)}}</p>
          </div>
        </template>
    </div>
</template>

<script>
    import "whatwg-fetch";
    import FNameInstructions from "@/components/fname/FNameInstructions";
    import FnameLearningPhase from "@/components/fname/learning/FNameLearningPhase";
    import FnameRecognitionPhase from "@/components/fname/recognition/FNameRecognitionPhase";
    import FnameMatchingPhase from "@/components/fname/matching/FNameMatchingPhase";
    import FNameInitialPhase from "@/components/fname/initials/FNameInitialPhase";
    import CountdownTimer from "@/components/CountdownTimer";
    import BasicInstructions from "@/components/BasicInstructions";

    export default {
        name: "FNameDriver",
        components: {
          BasicInstructions,
          CountdownTimer,
          FNameInitialPhase, FnameMatchingPhase, FnameRecognitionPhase, FnameLearningPhase, FNameInstructions, },
        props: ["json_data", "is_mobile"],
        mounted: function () {
            this.test_id = this.json_data.id;
            this.test_type = this.json_data.test_type;
            this.display_duration = this.json_data.display_seconds * 1000;
            this.learning_json_data = this.json_data.questions_learning;
            this.recognition_json_data = this.json_data.questions_recognition;
            this.matching_json_data = this.json_data.questions_matching;
            this.initial_json_data = this.json_data.questions_initial;
            this.recognition_json_data_delayed = this.json_data.questions_recognition_delayed;
            this.matching_json_data_delayed = this.json_data.questions_matching_delayed;
            this.initial_json_data_delayed = this.json_data.questions_initial_delayed;
            this.display_countdown_bar = this.json_data.display_countdown_bar
        },
        data: function() {
            return {
                step: 0,
                display_duration: 0,
                display_countdown_bar: false,
                learning_json_data: {},
                recognition_json_data: {},
                matching_json_data: {},
                initial_json_data: {},
                recognition_json_data_delayed: {},
                matching_json_data_delayed: {},
                initial_json_data_delayed: {},
                learning_results: [],
                recognition_results: [],
                matching_results: [],
                initials_results: [],
                recognition_delayed_results: [],
                matching_delayed_results: [],
                initials_delayed_results: [],
                task_scores: {
                  "recognition_score": 0,
                  "matching_score": 0,
                  "initials_score": 0,
                  "recognition_delayed_score": 0,
                  "matching_delayed_score": 0,
                  "initials_delayed_score": 0
                },
                test_id: null,
                test_type: null,
                complete: false,
                initial_score: 0,
                delayed_score: 0,
                averages: {},
                end_message: "Thank you for taking the Face-Name test."
            }
        },
        methods: {
            incrementStep: function(event) {
                this.step++;
                // There are only 9 steps defined in the template, so step 10 is when the Fname Test is complete
                if(this.step >= 10) {
                    this.complete = true;
                    this.$emit("submission", this.createResults(), "FNAME_DRIVER_COMPLETE_SUBMIT", this.complete)
                    // Need to let the data update from the above emit to avoid race conditions, "complete" will resubmit with an updated flag.
                    this.$nextTick(() => {

                      this.$emit('complete');
                    })
                }
            },
            decrementStep: function(event) {
                this.step--;
            },
            handleLearningSubmission: function(results) {
              this.learning_results = results.user_results
              this.averages["learning_averages"] = results.averages
              this.$emit("submission", this.createResults(), "FNAME_LEARNING_SUBMIT")
            },
            handleRecognitionSubmission: function(results) {
              this.recognition_results = results.user_results
              this.averages["recognition_averages"] = results.averages
              this.initial_score += results.score
              this.task_scores.recognition_score = results.score
              this.$emit("submission", this.createResults(), "FNAME_RECOGNITION_SUBMIT")
            },
            handleMatchingSubmission: function(results) {
              this.matching_results = results.user_results
              this.averages["matching_averages"] = results.averages
              this.initial_score += results.score
              this.task_scores.matching_score = results.score
              this.$emit("submission", this.createResults(), "FNAME_MATCHING_SUBMIT")
            },
            handleInitialsSubmission: function(results) {
              this.initials_results = results.user_results
              this.averages["initials_averages"] = results.averages
              this.initial_score += results.score
              this.task_scores.initials_score = results.score
              this.$emit("submission", this.createResults(), "FNAME_INITIALS_SUBMIT")
            },
          handleRecognitionDelayedSubmission: function(results) {
              this.recognition_delayed_results = results.user_results
              this.averages["recognition_delayed_averages"] = results.averages
              this.delayed_score += results.score
              this.task_scores.recognition_delayed_score = results.score
              this.$emit("submission", this.createResults(), "FNAME_RECOGNITION_DELAYED_SUBMIT")
            },
            handleMatchingDelayedSubmission: function(results) {
              this.matching_delayed_results = results.user_results
              this.averages["matching_delayed_averages"] = results.averages
              this.delayed_score += results.score
              this.task_scores.matching_delayed_score = results.score
              this.$emit("submission", this.createResults(), "FNAME_MATCHING_DELAYED_SUBMIT")
            },
            handleInitialsDelayedSubmission: function(results) {
              this.initials_delayed_results = results.user_results
              this.averages["initials_delayed_averages"] = results.averages
              this.delayed_score += results.score
              this.task_scores.initials_delayed_score = results.score
              this.$emit("submission", this.createResults(), "FNAME_INITIALS_DELAYED_SUBMIT")
            },
            createResults: function() {
              let total_initial_questions_answered = this.recognition_results.length + this.matching_results.length + this.initials_results.length
              let total_delayed_questions_answered = this.recognition_delayed_results.length + this.matching_delayed_results.length + this.initials_delayed_results.length
              let total_questions_answered = total_initial_questions_answered + total_delayed_questions_answered
              return {
                start_time: this.time_start,
                finish_time: this.time_end,
                test_id: this.test_id,
                test_type: this.test_type,
                is_complete: this.complete,
                user_results: {
                    learning: this.learning_results,
                    recognition: this.recognition_results,
                    matching: this.matching_results,
                    initials: this.initials_results,
                    recognition_delayed: this.recognition_delayed_results,
                    matching_delayed: this.matching_delayed_results,
                    initials_delayed: this.initials_delayed_results,
                },
                averages: this.averages,
                scores: {
                  total_score: this.initial_score + this.delayed_score,
                  initial_score: this.initial_score,
                  delayed_score: this.delayed_score,
                  initial_questions_answered: total_initial_questions_answered,
                  total_delayed_questions_answered: total_delayed_questions_answered,
                  total_questions_answered: total_questions_answered
                },
                task_scores: this.task_scores,
              }
            }


        }
    }
</script>

<style scoped>
</style>