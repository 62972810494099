import Vue from 'vue'
import App from './App.vue'
import 'bootstrap'
import VueBrowserupdate from "@sum.cumo/vue-browserupdate/index";
import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { faSpinner } from '@fortawesome/free-solid-svg-icons'

library.add(faSpinner)
Vue.component('font-awesome-icon', FontAwesomeIcon)

Vue.prototype.gettext = window.gettext
Vue.config.productionTip = false
new Vue({
  render: h => h(App),
  created() {
    window.onbeforeunload = function(e) {
      // Older browsers
      if(e) {
        e.returnValue = this.gettext('Changes you made may not be saved');
      }
      return this.gettext('Changes you made may not be saved');
    }
  }
}).$mount('#app')

Vue.use(VueBrowserupdate, {
  options: {
    required: { e: -4, f: -3, o: -3, s: -1, c:-3 },
      insecure: true,
      unsupported: true,
      reminder: 0,
      noclose: false,
      no_permanent_hide: true,
      text: {
        msgmore: 'Use the latest version of Google Chrome, Mozilla Firefox, Microsoft Edge, or Safari.',
        bupdate: 'Update'
       },
     api:2023.09
  }
})
