<template>
  <div class="p-3">
        <template v-if="step === 0">
          <BasicInstructions v-bind:instruction_text="gettext('Now please select the face you saw.')"
                             v-on:next="incrementStep"
                             :display_bottom_button="true"
                             :left_text="is_mobile"
          ></BasicInstructions>
        </template>
        <template v-if="step === 1">
          <ImageSelectionList :question_list="imageOrder"
                              :question_text="gettext('What face did you see?')"
                              :key="currentQuestion"
                              :button_text="gettext('OK')"
                              :item_classname="is_mobile ? 'img-stimuli-sm': ''"
                              :display_bottom_button="true"
                              :has_background="false"
                              v-on:submission="handleQuestionSubmission"
          ></ImageSelectionList>
        </template>
    </div>
</template>

<script>
import BasicInstructions from "@/components/BasicInstructions";
import BasicStimuliImage from "@/components/BasicStimuliImage";
import timing from "@/timing";
import ImageSelectionList from "@/components/ImageSelectionList";
export default {
  name: "FnameRecognitionPhase",
  components: {ImageSelectionList, BasicInstructions},
  props: ["questions", "is_mobile"],
  mixins: [timing],
  mounted: function() {
    this.imageOrder = [{"url": this.questions[0].target_image}, {"url": this.questions[0].foil_images[0]}, {"url": this.questions[0].foil_images[1]}]
    this.durstenfeldShuffle(this.imageOrder)
    this.$nextTick(function() {
      this.recordStartTime()
    })
  },
  data: function() {
    return {
      time_start: 0,
      time_end: 0,
      step:0,
      selectedImage: null,
      selectedNumber: null,
      currentQuestion: 0,
      currentImage: null,
      selection_time: 0,
      submission_time: 0,
      complete: false,
      responses: [],
      imageOrder: [],
      score: 0,
      averages: {}
    }
  },
  methods: {
    incrementStep: function (event) {
      this.step++;
      this.recordStartTime()
    },
    // Modifies the original array
    durstenfeldShuffle: function(arr) {
      for(let i = arr.length -1; i>0; i--) {
        const j = Math.floor(Math.random() *(i+1));
        [arr[i], arr[j]] = [arr[j], arr[i]]
      }
    },

    incrementQuestion: function (event) {
      window.scrollTo(0,0)
      this.currentQuestion++;
      this.recordStartTime()
      // If we are out of questions, move to the next phase
      if (this.currentQuestion >= this.questions.length) {
        this.complete = true;
        this.averages = this.calculateAvgs()
        this.$emit('complete', this.responses);
        this.$emit('submission', this.createResult())
        // Don't try to randomize image order and the like
        return
      }
      //set the new image order
      this.imageOrder = [{"url": this.questions[this.currentQuestion].target_image}, {"url": this.questions[this.currentQuestion].foil_images[0]}, {"url": this.questions[this.currentQuestion].foil_images[1]}]
      // Randomize the image order
      this.durstenfeldShuffle(this.imageOrder)
    },
    handleQuestionSubmission: function(selection, selection_cnt, selection_time, submission_time) {
      this.selectedNumber = selection
      this.selectedImage = this.imageOrder[selection].url
      this.selection_time = selection_time
      this.submission_time = submission_time
      this.responses.push({
        "name": this.questions[this.currentQuestion].name,
        'question_id': this.questions[this.currentQuestion].question_id,
        'response_number': this.se,
        'response_image': this.selectedImage,
        'correct': this.selectedImage === this.questions[this.currentQuestion].target_image,
        'selection_time': this.selection_time,
        'submission_time': this.submission_time,
        'response_time': this.selection_time + this.submission_time
      })
      this.incrementQuestion()
    },

    buttonCallback: function(imageUrl, number) {
      this.recordEndTime()
      this.selectedImage = imageUrl
      this.selectedNumber = number
      this.responses.push({
        "name": this.questions[this.currentQuestion].name,
        'question_id': this.questions[this.currentQuestion].question_id,
        'response_number': this.selectedNumber,
        'response_image': this.selectedImage,
        'correct': this.selectedImage === this.questions[this.currentQuestion].target_image,
        'response_time': this.getTimeDiff()
      })
      this.incrementQuestion()
    },
    calculateAvgs: function() {
      let cumulative_corr_rt = 0
      let cumulative_incorr_rt = 0
      let total_corr_count = 0
      let total_incorrect_count = 0
      this.responses.forEach((response) => {
        if(response.correct) {
          cumulative_corr_rt += response.response_time
          total_corr_count += 1
        }
        else {
          cumulative_incorr_rt += response.response_time
          total_incorrect_count +=1
        }
      })

      let corr_denom = total_corr_count > 0
      let incorr_denom = total_incorrect_count > 0
      this.score = total_corr_count
      return {
        "avg_corr_rt": corr_denom ? cumulative_corr_rt / total_corr_count : 0,
        "avg_incorr_rt": incorr_denom ? cumulative_incorr_rt / total_incorrect_count : 0,
      }
    },

    createResult: function() {
      return {
        "user_results": this.responses,
        "averages": this.averages,
        "score": this.score
      }
    }

  }
}
</script>

<style scoped>

</style>