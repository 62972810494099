<template>
  <div class="p-lg-3 p-sm-1">
        <template v-if="step === 0">
          <BasicInstructions v-bind:instruction_text="gettext('Try to remember the name associated with each face.')"
                             :second_text="gettext('Please indicate whether the name fits each face or not.')"
                             v-on:next="incrementStep"
                             :left_text="is_mobile"
                             :display_bottom_button="true"
          ></BasicInstructions>
        </template>
        <template v-if="step === 1">
          <div class="">
            <div class="justify-content-center text-center my-2">
              <p>{{ gettext('Does the name fit this face?')}}</p>
            </div>
                <template v-if="displayImage">
                  <BasicStimuliImage :custom_classname="is_mobile ? 'img-stimuli-sm' : ''" :image_url="questions[currentQuestion].target_image" :id="`image-${questions[currentQuestion].question_id}`" :key="`fname-learn-${questions[currentQuestion].question_id}-${questions[currentQuestion].ordering_id}`"></BasicStimuliImage>
                </template>
                <template v-else>
                  <BasicStimuliImage :custom_classname="is_mobile ? 'img-stimuli-sm' : ''" :image_url="template_image" :id="'image-template'"></BasicStimuliImage>
                </template>
                <h2 class="my-lg-4 my-sm-2">{{this.questions[currentQuestion].target_name}}</h2>
          </div>
          <div class="d-flex align-items-stretch justify-content-center my-4">
                <button class="btn btn-lg btn-primary mx-2" v-on:click="buttonCallback('yes')">{{ gettext('Yes') }}</button>
                <button class="btn btn-lg btn-primary mx-2" v-on:click="buttonCallback('no')">{{ gettext('No') }}</button>
                <button class="btn btn-lg btn-primary mx-2" v-on:click="buttonCallback('undecided')">{{ gettext('Undecided') }}</button>
          </div>
          <CustomProgressBar :display_countdown_bar="display_countdown_bar" :key="`progress-bar-key-${currentQuestion}`" v-on:complete="imageTimeout" :timeSeconds="displayDuration/1000"></CustomProgressBar>
        </template>
    </div>
</template>

<script>
import BasicInstructions from "@/components/BasicInstructions";
import BasicStimuliImage from "@/components/BasicStimuliImage";
import timing from "@/timing";
import CustomProgressBar from "@/components/customProgressBar";
export default {
  name: "FnameLearningPhase",
  components: {CustomProgressBar, BasicInstructions, BasicStimuliImage},
  props: ["questions", "displayDuration", "is_mobile", "display_countdown_bar"],
  mixins: [timing],

  mounted: function() {
    this.$nextTick(function() {
        this.recordStartTime();
    })
  },
  data: function() {
    return {
      time_start: 0,
      time_end: 0,
      step:0,
      selectedResponse: null,
      currentQuestion: 0,
      currentImage: null,
      complete: false,
      displayImage: true,
      responded: false,
      template_image: "/static/img/placeholder.png",
      responses: [],
      averages: {},
      cumulative_response_time: 0,
    }
  },
  methods: {
    incrementStep: function (event) {
      this.step++;
      this.recordStartTime()
    },
    recordNoResponse: function() {
      this.responses.push({
        "name": this.questions[this.currentQuestion].name,
        "question_id": this.questions[this.currentQuestion].question_id,
        "responded": false,
        'response_time': 0,
      })
    },
    // The progress bar controls the timer
    imageTimeout: function (event) {
      this.recordNoResponse()
      this.incrementQuestion()
    },

    incrementQuestion: function (event) {
      window.scrollTo(0,0)
      this.currentQuestion++;
      this.displayImage = true
      this.recordStartTime()
      // If we are out of questions, move to the next phase
      if (this.currentQuestion >= this.questions.length) {
        this.complete = true;
        this.$emit('complete', this.responses);
        this.calculateAvgs()
        this.$emit('submission', this.createResult());
      }
    },

    calculateAvgs: function () {
      let response_length = this.responses.length
      response_length = response_length === 0 ? 1 : response_length
      let avg_rt = this.cumulative_response_time / response_length
      this.averages = {'avg_rt': avg_rt}
    },

    buttonCallback: function(value) {
      this.recordEndTime()
      this.selectedResponse = value
      let response_time = this.getTimeDiff()
      this.responses.push({
        "name": this.questions[this.currentQuestion].name,
        'question_id': this.questions[this.currentQuestion].question_id,
        'response': value,
        'response_time': response_time,
        'responded': true,
      })
      this.cumulative_response_time +=  response_time
      this.incrementQuestion()
    },

    createResult: function() {
      return {
        "user_results": this.responses,
        "averages": this.averages
      }
    }

  }
}
</script>

<style scoped>

</style>