<template>
    <div :class="(!top_text ? 'mt-auto ': '') +'flex-column mx-sm-1 mx-md-2 media-body card-input'">
        <p v-if="top_text" class="text-center confidence-text">{{ gettext(top_text) }}</p>
        <label v-bind:for="name + display_text" class="w-100">
            <!-- Instead of emitting $event.target.value we emit the actual_val prop. This is to handle the case where we want the value to be the actual value "type" and not a string -->
            <input type="radio" :id="name + display_text" :value="actual_val" :name="name" v-model="model_val" v-on:input="input">
            <div class="card">
                <div class="card-body">
                    <p class="card-text">{{ gettext(display_text) }}</p>
                </div>
            </div>
        </label>
    </div>
</template>

<script>
    export default {
        name: "BasicCardInput",
        props: ["display_text", "name", "actual_val", "model_val", 'top_text'],
        methods: {
            input: function(event) {
                this.$emit('updateError');
                this.$emit('input', this.actual_val);
            }
        }
    }
</script>

<style scoped>
    label {
        cursor: pointer;
    }
    input[type="radio"] {
        visibility: hidden;
    }
    input[type="radio"]:checked+div {
        outline: 3px solid red;
        position: relative;
        z-index: 100;
    }
</style>