<template>
    <div>
        <template v-if="step === 0">
            <DelayedQuestion :question_obj="json_data[question_num]"
                             :key="question_num"
                             v-on:submission="questionSub"></DelayedQuestion>
        </template>

    </div>
</template>

<script>
    import DelayedQuestion from "./DelayedQuestion";

    export default {
        name: "DelayedPhase",
        mixins: [],
        components: {DelayedQuestion},
        props: ["json_data"],
        data: function() {
            return {
                step: 0,
                question_num: 0,
                max_questions: 0,
                user_responses: [],
            }
        },

        mounted: function() {
            this.max_questions = this.json_data.length;
            this.preloadImages();
        },

        methods: {
            incrementStep: function(event) {
                this.step++;
            },
            decrementStep: function(event) {
                this.step--;
            },
            incrementRound: function(event) {
                this.question_num++;
                if(this.question_num >= this.max_questions) {
                    this.step++;
                }
            },

            preloadImages: function(event) {
                for(let quest_obj of this.json_data) {
                    const target_image = new Image();
                    target_image.src = quest_obj.target_image;
                    for(let stim_list of quest_obj.stimuli) {
                        for(let stim of stim_list) {
                            const image = new Image();
                            image.src = stim.url;
                        }
                    }
                }
            },
            questionSub: function(data) {
                this.user_responses.push(data);
                this.$emit("submission", this.createResults());
                this.incrementRound();
                if(this.step === 1) {
                    this.$emit("complete");
                }
            },

            createResults: function() {
                return {
                    user_responses: this.user_responses,
                }
            }
        }
    }
</script>

<style scoped>

</style>