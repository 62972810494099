<template>
    <div class="progress" :style="display_countdown_bar ?  '' : 'visibility: hidden'">
        <div class="progress-bar" role="progressbar" :aria-valuenow="current_percent" aria-valuemin="0" aria-valuemax="100" :style="`width: ${current_percent}%`"></div>
    </div>
</template>

<script>
    export default {
        components: {},
        props: ["timeSeconds", "display_countdown_bar"],
        mounted: function () {
          this.timeRemaining = this.timeSeconds
          // Since we update the time remaining every 1/10 second. Decrement needs to be 1/10
          this.step_decrement = 100/(this.timeSeconds * 10)
        },

        data: function() {
              return {
                  // timeRemaining is in seconds
                  current_percent: 100,
                  timeRemaining: 0,
                  step_decrement: 1,
              }
          },
      methods: {
          // Used to remove ridiculous JS floating point at 10 decimals deep for timer
          precisionRound: function(value, precision) {
            let multiplier = Math.pow(10, precision || 0)
            return Math.round(value*multiplier) / multiplier
          }
      },
        watch: {
          timeRemaining: {
            // Every second update the time remaining
            handler(value) {
              if(value > 0 && this.current_percent > 0) {
                setTimeout(() => {
                  this.timeRemaining -= .1
                  this.timeRemaining = this.precisionRound(this.timeRemaining, 1)
                  this.current_percent -= this.step_decrement
                  if(this.timeRemaining <= 0) {
                    this.$emit("complete")
                  }
                }, 100)
              }
            },
            immediate: true // This ensures the watcher is triggered upon creation
          }
        },
    }
</script>