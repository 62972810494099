<template>
    <div class="error-text" :style="error ? 'display:block' : 'display:none' ">
        <template v-if="error">
        {{ gettext(error_msg) }}
        </template>
    </div>
</template>

<script>
    export default {
        name: "BasicError",
        props: ["error", "error_msg"],
        data: function() {
            return {

            }
        }
    }
</script>

<style scoped>
.error-text {
    color: #dc3545;
    width: 100%;
    margin-top: .25rem;

}
</style>