<template>
    <div class="d-flex justify-content-end my-lg-4 my-sm-1">
        <button class="btn btn-lg btn-primary" v-on:click="click_callback">{{ gettext(button_text) }}</button>
    </div>
</template>

<script>
    export default {
        name: "RightAlignButton",
        props: ["click_callback", "button_text"],
    }
</script>

<style scoped>

</style>