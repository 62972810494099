<template>
    <div class="p-3">
        <template v-if="step === 0">
            <RoomPractice :displayDuration="display_duration" v-on:complete="incrementStep"></RoomPractice>
        </template>

        <template v-else-if="step === 1">
            <MemoryPhase :json_data="memory_json_data"
                         :display_duration="display_duration"
                         v-on:submission="memorySubmission"
                         v-on:complete="incrementStep"></MemoryPhase>
        </template>

        <template v-else-if="step === 2">
            <BasicInstructions :instruction_text="gettext('Press OK to start the next question.')"
                                   v-on:next="incrementStep"></BasicInstructions>
        </template>

        <template v-else-if="step === 3">
            <DelayedPhase :json_data="delayed_json_data"
                          v-on:submission="delayedSub"
                          v-on:complete="incrementStep"></DelayedPhase>
        </template>
    </div>
</template>

<script>
    import BasicInstructions from "./BasicInstructions";
    import DelayedPhase from "./DelayedPhase";
    import MemoryPhase from "./MemoryPhase";


    import "whatwg-fetch";
    import RoomPractice from "@/components/RoomPractice";

    export default {
        name: "RoomDriver",
        components: {RoomPractice, MemoryPhase, DelayedPhase, BasicInstructions},
        props: ["json_data"],
        mounted: function () {
            this.test_id = this.json_data.id;
            this.test_type = this.json_data.test_type;
            this.display_duration = this.json_data.duration_room_display * 1000;
            this.memory_json_data = this.json_data.questions;
            this.delayed_json_data = this.json_data.delayed_questions;
        },
        data: function() {
            return {
                step: 0,
                display_duration: 0,
                memory_json_data: {},
                delayed_json_data: {},
                test_id: null,
                test_type: null,
                complete: false,
                memory_phase_results: {},
                delayed_phase_results: {},
                averages: {},
            }
        },
        methods: {
            incrementStep: function(event) {
                this.step++;
                // There are only 2 steps defined in the template, so step 3 is when the Room Test is complete
                if(this.step >= 4) {
                    this.calculateAvgs();
                    this.complete = true;
                    this.$emit('submission', this.createResults(), "ROOM_DRIVER_COMPLETE_SUBMIT", this.complete)
                    // Need to let the data update from the above emit to avoid race conditions, "complete" will resubmit with an updated flag.
                    this.$nextTick(() => {
                      this.$emit('complete');
                    })

                }
            },
            decrementStep: function(event) {
                this.step--;
            },

            memorySubmission: function(results) {
                this.memory_phase_results = results;
                // Post the partial results
                this.$emit("submission", this.createResults(), "ROOM_MEMORY_PHASE_SUBMIT")
            },

            delayedSub: function(results) {
                this.delayed_phase_results = results;
                // Post the partial results
                this.$emit("submission", this.createResults(), "ROOM_DELAYED_PHASE_SUBMIT");

            },

            createResults: function() {
                return {
                    start_time: this.time_start,
                    finish_time: this.time_end,
                    test_id: this.test_id,
                    test_type: this.test_type,
                    is_complete: this.complete,
                    user_results: {
                        memory: this.memory_phase_results,
                        delayed: this.delayed_phase_results,
                    },
                    averages: this.averages
                }
            },

            calculateMemoryAvgs: function() {
                // Declare Cumulative totals
                let mem_correct_cnt = 0;
                let mem_total = 0;
                let place_correct_cnt = 0;
                let place_meta_correct_cnt = 0;

                let stim_selection_time = 0;
                let stim_selection_sub_time = 0;
                let stim_selection_time_corr = 0;
                let stim_selection_time_wrong = 0;
                let stim_selection_sub_time_corr = 0;
                let stim_selection_sub_time_wrong = 0;

                let place_time = 0;
                let place_sub_time = 0;
                let place_time_corr = 0;
                let place_time_wrong = 0;
                let place_sub_time_corr = 0;
                let place_sub_time_wrong = 0;

                // Should be the length of the number of questions. Since each question has 1 placement
                let place_total = this.memory_phase_results.user_responses.length;
                for(let question of this.memory_phase_results.user_responses) {
                    mem_total += question.memory_selections.length;
                    for(let answer of question.memory_selections) {
                        stim_selection_time += answer.select_time;
                        stim_selection_sub_time += answer.sub_time;
                        if(answer.is_correct) {
                            mem_correct_cnt++;
                            stim_selection_time_corr += answer.select_time;
                            stim_selection_sub_time_corr += answer.sub_time;
                        }
                        else {
                            stim_selection_time_wrong += answer.select_time;
                            stim_selection_sub_time_wrong += answer.sub_time;
                        }
                    }
                    if(question.placement.is_correct) {
                        place_correct_cnt++;
                        place_time_corr += question.placement.place_time;
                        place_sub_time_corr += question.placement.sub_time;
                    }
                    else {
                        place_time_wrong += question.placement.place_time;
                        place_sub_time_wrong += question.placement.sub_time;
                    }
                    if(question.placement.is_meta_correct) {
                        place_meta_correct_cnt++;
                    }
                    place_time += question.placement.place_time;
                    place_sub_time += question.placement.sub_time;
                }
                // Need to calculate these first since we may change the numerators value to be used as a denominator for response times.
                let mem_score = mem_correct_cnt / mem_total;
                let place_score = place_correct_cnt / place_total;
                let place_meta_score = place_meta_correct_cnt / place_total;

                let avg_select_rt = stim_selection_time / mem_total;
                let avg_select_sub_rt = stim_selection_sub_time / mem_total;
                let avg_place_rt = place_time / place_total;
                let avg_place_sub_rt = place_sub_time / place_total;

                // Ensure our denominators are not 0
                let mem_wrong_cnt = mem_correct_cnt < mem_total ? mem_total - mem_correct_cnt : 1;
                mem_correct_cnt = mem_correct_cnt > 0 ? mem_correct_cnt : 1;
                let place_wrong_cnt = place_correct_cnt < place_total ? place_total - place_correct_cnt : 1;
                place_correct_cnt = place_correct_cnt > 0 ? place_correct_cnt : 1;

                let avg_select_corr_rt = stim_selection_time_corr / mem_correct_cnt;
                let avg_select_sub_corr_rt  = stim_selection_sub_time_corr / mem_correct_cnt;
                let avg_select_wrong_rt = stim_selection_time_wrong / mem_wrong_cnt;
                let avg_select_sub_wrong_rt = stim_selection_sub_time_wrong / mem_wrong_cnt;


                let avg_place_corr_rt = place_time_corr / place_correct_cnt;
                let avg_place_sub_corr_rt = place_sub_time_corr / place_correct_cnt;
                let avg_place_wrong_rt = place_time_wrong / place_wrong_cnt;
                let avg_place_sub_wrong_rt = place_sub_time_wrong / place_wrong_cnt;


                // console.log(this.memory_phase_results);
                // console.log(`mem_score: ${mem_score}\t place_score: ${place_score}\t place_meta_score: ${place_meta_score}`);
                // console.log(`avg_select_rt: ${avg_select_rt}\t avg_select_sub_rt: ${avg_select_sub_rt}`);
                // console.log(`avg_place_rt: ${avg_place_rt}\t avg_place_sub_rt: ${avg_place_sub_rt}`);
                // console.log(`avg_select_corr_rt ${avg_select_corr_rt}\t avg_select_sub_corr_rt: ${avg_select_sub_corr_rt}`);
                // console.log(`avg_select_wrong_rt: ${avg_select_wrong_rt}\t avg_select_sub_wrong_rt: ${avg_select_sub_wrong_rt}`);
                // console.log(`avg_place_corr_rt: ${avg_place_corr_rt}\t avg_place_sub_corr_rt: ${avg_place_sub_corr_rt}`);
                // console.log(`avg_place_wrong_rt: ${avg_place_wrong_rt}\t avg_place_sub_wrong_rt: ${avg_place_sub_wrong_rt}`);
                return {
                    select_score: mem_score,
                    place_score: place_score,
                    place_meta_score: place_meta_score,
                    avg_select_rt: avg_select_rt,
                    avg_select_sub_rt: avg_select_sub_rt,
                    avg_select_corr_rt: avg_select_corr_rt,
                    avg_select_sub_corr_rt: avg_select_sub_corr_rt,
                    avg_select_wrong_rt: avg_select_wrong_rt,
                    avg_select_sub_wrong_rt: avg_select_sub_wrong_rt,
                    avg_place_rt: avg_place_rt,
                    avg_place_sub_rt: avg_place_sub_rt,
                    avg_place_corr_rt: avg_place_corr_rt,
                    avg_place_sub_corr_rt: avg_place_sub_corr_rt,
                    avg_place_wrong_rt: avg_place_wrong_rt,
                    avg_place_sub_wrong_rt: avg_place_sub_wrong_rt,
                }
            },

            calculateDelayedAvgs: function() {
                // Declare Cumulative totals
                let delay_correct_cnt = 0;
                let place_correct_cnt = 0;
                let place_meta_correct_cnt = 0;

                let select_time = 0;
                let select_sub_time = 0;
                let select_time_corr = 0;
                let select_time_wrong = 0;
                let select_sub_time_corr = 0;
                let select_sub_time_wrong = 0;

                let place_time = 0;
                let place_sub_time = 0;
                let place_time_corr = 0;
                let place_time_wrong = 0;
                let place_sub_time_corr = 0;
                let place_sub_time_wrong = 0;

                let delay_total = this.delayed_phase_results.user_responses.length;
                for(let answer of this.delayed_phase_results.user_responses) {
                    select_time += answer.delayed_selection.select_time;
                    select_sub_time += answer.delayed_selection.sub_time;
                    place_time += answer.placement.place_time;
                    place_sub_time += answer.placement.sub_time;
                    if(answer.delayed_selection.is_correct) {
                        delay_correct_cnt++;
                        select_time_corr += answer.delayed_selection.select_time;
                        select_sub_time_corr += answer.delayed_selection.sub_time;
                    }
                    else {
                        select_time_wrong += answer.delayed_selection.select_time;
                        select_sub_time_wrong += answer.delayed_selection.sub_time;
                    }
                    if(answer.placement.is_correct) {
                        place_correct_cnt++;
                        place_time_corr += answer.placement.place_time;
                        place_sub_time_corr += answer.placement.sub_time;
                    }
                    else {
                        place_time_wrong += answer.placement.place_time;
                        place_sub_time_wrong += answer.placement.sub_time;
                    }
                    if(answer.placement.meta_correct) {
                        place_meta_correct_cnt++;
                    }
                }
                // Need to calculate these first since we may change the numerators value to be used as a denominator for response times.
                let delay_score = delay_correct_cnt / delay_total;
                let place_score = place_correct_cnt / delay_total;
                let place_meta_score = place_meta_correct_cnt / delay_total;

                let avg_select_rt = select_time / delay_total;
                let avg_select_sub_rt = select_sub_time / delay_total;
                let avg_place_rt = place_time / delay_total;
                let avg_place_sub_rt = place_sub_time / delay_total;
                // Ensure our denominators are not 0
                let delay_wrong_cnt = delay_correct_cnt < delay_total ? delay_total - delay_correct_cnt : 1;
                delay_correct_cnt = delay_correct_cnt > 0 ?  delay_correct_cnt : 1;
                let place_wrong_cnt = place_correct_cnt < delay_total ? delay_total - place_correct_cnt : 1;
                place_correct_cnt = place_correct_cnt > 0 ? place_correct_cnt : 1;

                let avg_select_corr_rt = select_time_corr / delay_correct_cnt;
                let avg_select_sub_corr_rt = select_sub_time_corr / delay_correct_cnt;
                let avg_select_wrong_rt = select_time_wrong / delay_wrong_cnt;
                let avg_select_sub_wrong_rt = select_sub_time_wrong / delay_wrong_cnt;


                let avg_place_corr_rt = place_time_corr / place_correct_cnt;
                let avg_place_sub_corr_rt = place_sub_time_corr / place_correct_cnt;
                let avg_place_wrong_rt = place_time_wrong / place_wrong_cnt;
                let avg_place_sub_wrong_rt = place_sub_time_wrong / place_wrong_cnt;

                // console.log(`delay_score: ${delay_score}\t place_score: ${place_score}\t place_meta_score: ${place_meta_score}`);
                // console.log(`avg_select_rt: ${avg_select_rt}\t avg_select_sub_rt: ${avg_select_sub_rt}`);
                // console.log(`avg_place_rt: ${avg_place_rt}\t avg_place_sub_rt: ${avg_place_sub_rt}`);
                // console.log(`avg_select_corr_rt ${avg_select_corr_rt}\t avg_select_sub_corr_rt: ${avg_select_sub_corr_rt}`);
                // console.log(`avg_select_wrong_rt: ${avg_select_wrong_rt}\t avg_select_sub_wrong_rt: ${avg_select_sub_wrong_rt}`);
                // console.log(`avg_place_corr_rt: ${avg_place_corr_rt}\t avg_place_sub_corr_rt: ${avg_place_sub_corr_rt}`);
                // console.log(`avg_place_wrong_rt: ${avg_place_wrong_rt}\t avg_place_sub_wrong_rt: ${avg_place_sub_wrong_rt}`);
                // console.log(this.delayed_phase_results);
                return {
                    select_score: delay_score,
                    place_score: place_score,
                    place_meta_score: place_meta_score,
                    avg_select_rt: avg_select_rt,
                    avg_select_sub_rt: avg_select_sub_rt,
                    avg_select_corr_rt: avg_select_corr_rt,
                    avg_select_sub_corr_rt: avg_select_sub_corr_rt,
                    avg_select_wrong_rt: avg_select_wrong_rt,
                    avg_select_sub_wrong_rt: avg_select_sub_wrong_rt,
                    avg_place_rt: avg_place_rt,
                    avg_place_sub_rt: avg_place_sub_rt,
                    avg_place_corr_rt: avg_place_corr_rt,
                    avg_place_sub_corr_rt: avg_place_sub_corr_rt,
                    avg_place_wrong_rt: avg_place_wrong_rt,
                    avg_place_sub_wrong_rt: avg_place_sub_wrong_rt,
                }
            },

            calculateAvgs: function() {
                this.averages.memory_phase = this.calculateMemoryAvgs();
                this.averages.delayed_phase = this.calculateDelayedAvgs()
            }


        }
    }
</script>

<style scoped>
</style>