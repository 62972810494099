<template>
    <div>
        <div class="justify-content-end">
            <p class="text-right mx-2">{{question_num+1}}/{{question_total}}</p>
        </div>
        <div class="d-flex justify-content-center">
            <template v-if="step === 0">
                <div class="img-container">
                    <BasicStimuliImage :image_url="this.stimuli_separator" id="image-separator"></BasicStimuliImage>
                    <BasicStimuliImage v-for="(img, index) in question_obj.stimuli"
                                       :key="img.image"
                                       :image_url="img.image"
                                       :id="`image-${index}`">
                    </BasicStimuliImage>
                </div>
            </template>

            <template v-else-if="step === 1">
                <div>
                    <p class="max-width-750 mx-auto d-non d-sm-block d-lg-none"><strong><u>{{ gettext("Make sure you scroll down to see all question options!") }}</u></strong></p>
                    <div class="img-container">

                        <BasicStimuliImage :image_url="question_obj.prompt"></BasicStimuliImage>
                    </div>
                    <div>
                        <p>{{ gettext("Did you see this object?") }}</p>
                        <div class="btn-group d-flex my-3" role="group">
                            <button class="btn btn-lg btn-primary mx-3"
                                    :name="'user_response'"
                                    :value="'yes'"
                                    v-on:click="nextQuestion">{{ gettext("Yes") }}</button>
                            <button class="btn btn-lg btn-primary mx-3"
                                    :name="'user_response'"
                                    :value="'no'"
                                    v-on:click="nextQuestion">{{ gettext("No") }}</button>
                        </div>
                    </div>
                </div>
            </template>
        </div>
    </div>
</template>

<script>

    import timing from "../timing";
    import BasicStimuliImage from "./BasicStimuliImage";

    export default {
        name: "SeriesQuestion",
        components: {BasicStimuliImage},
        mixins: [timing],
        props: ["question_num", "question_total", "time_interval", "question_obj", "stimuli_separator"],

        mounted: function() {
            // Don't record start time here because we show an interval of objs first. Show start time at end of the interval
            // this.recordStartTime()

            this.max_stimuli = this.question_obj.stimuli.length;
            this.hidePreloadedImages();
            this.interval_obj = setInterval(this.updateStimuli, this.time_interval);
        },

        data: function() {
            return {
                time_start: 0,
                time_end: 0,
                step: 0,
                stimuli_num: 0,
                max_stimuli: 0,
                interval_obj: null,
                user_response: null,
                is_correct: null,
                response_time: 0,
            }
        },

        methods: {
            incrementStep: function(event) {
                this.step++;
            },
            decrementStep: function(event) {
                this.step--;
            },

            hidePreloadedImages: function(event) {
                for(let i=0; i < this.max_stimuli; i++) {
                    let element = document.getElementById(`image-${i}`);
                    element.className += " d-none"
                }
            },

            hideImage: function(element_id) {
                let element = document.getElementById(element_id);
                element.className += " d-none";
            },

            showImage: function(element_id) {
                let element = document.getElementById(element_id);
                element.className = "img-stimuli"
            },

            updateStimuli: function(event) {
                if(this.stimuli_num < this.max_stimuli) {
                    this.showImage(`image-${this.stimuli_num}`);
                    this.hideImage(this.stimuli_num === 0 ? "image-separator" : `image-${this.stimuli_num-1}`);
                }
                else if(this.stimuli_num === this.max_stimuli) {
                    this.showImage("image-separator");
                    this.hideImage(`image-${this.stimuli_num-1}`)
                }
                else {
                    this.hideImage('image-separator');
                    clearTimeout(this.interval_obj);
                    this.incrementStep();
                    this.recordStartTime();
                }
                this.stimuli_num++;
            },

            nextQuestion: function(event) {
                this.recordEndTime();
                this.user_response = event.target.value;
                this.validateAnswer();
                this.response_time = this.getTimeDiff();

                this.$emit("submission", this.createResults());
            },
            validateAnswer: function(event) {
                let yes_correct = this.user_response === "yes" && this.question_obj.is_prompt_correct;
                let no_correct = this.user_response === 'no' && !this.question_obj.is_prompt_correct;
                this.is_correct = (yes_correct || no_correct);
            },

            createResults: function(event) {
                return {
                    id: this.question_obj.id,
                    name: this.question_obj.name,
                    group: this.question_obj.group,
                    is_correct: this.is_correct,
                    user_response: this.user_response,
                    response_time: this.response_time,
                    stimuli_length: this.question_obj.stimuli.length,
                }
            },

        }
    }
</script>

<style scoped>
    .img-container {
        border: 8px solid black
    }

</style>