<template>
  <div>
    <div class="d-flex justify-content-start align-items-center position-fixed">
      <div class="chip chip-orange d-flex">
          <p class="m-1">{{gettext("Practice")}}</p>
      </div>
    </div>
    <div class="p-sm-0 p-lg-3">

        <template v-if="step === 0">
            <BasicInstructions v-bind:instruction_text="gettext('Now, you are going to observe a room with several objects. Please, take a careful look, as you will be asked later about it. Do your best as fast as possible.')"
                               :mobile_text="gettext('Please rotate your phone to landscape mode for the best experience. You may zoom in and out by using two fingers on the screen.')"
                               :mobile_text_special="gettext('Make sure you scroll down to see all question options!')"
                               :bold_text="gettext('Do not use the back button as it will restart the test.')"
                               v-on:next="incrementStep"
            >
            </BasicInstructions>
        </template>
        <template v-else-if="step === 1 ">
          <MemoryPhase :json_data="mock_data"
                       :display_duration="displayDuration"
                       v-on:complete="incrementStep">
          </MemoryPhase>
        </template>

        <template v-else-if="step === 2 ">
            <BasicInstructions :instruction_text="gettext('Press OK to start the next question.')"
                               v-on:next="incrementStep">
            </BasicInstructions>
        </template>

        <template v-else-if="step === 3 ">
          <DelayedPhase :json_data="mock_delayed_data"
                        v-on:complete="incrementStep">
          </DelayedPhase>
        </template>

        <template v-else-if="step === 4">
          <div class="container align-content-center">
            <div class="my-3">
                <h2>{{gettext('End of the practice test!')}}</h2>
                <p class="mt-lg-5 mt-sm-3 max-width-750 mx-auto">{{ gettext('Would you like to retake the practice test?') }}</p>
                <div class="d-flex justify-content-center">
                  <div>
                    <button class="btn btn-lg btn-primary mx-2" v-on:click="setStep(0)">{{gettext('Yes')}}</button>
                    <button class="btn btn-lg btn-primary mx-2" v-on:click="incrementStep">{{gettext('No')}}</button>
                  </div>
                </div>
            </div>
          </div>
        </template>
    </div>
  </div>
</template>

<script>
    import BasicInstructions from "@/components/BasicInstructions";
    import MemoryPhase from "@/components/MemoryPhase";
    import DelayedPhase from "@/components/DelayedPhase";
    export default {
        components: {
          DelayedPhase,
          MemoryPhase,
          BasicInstructions},
        props: ["displayDuration", ],
        data: function() {
              return {
                  step: 0,
                  complete: false,
                  mock_data: [
                      {
                        "id": 2,
                        "name": "Kids Room",
                        "room_image_before": "/static/img/RoomPracticeTest/KidsRoom/kids_room_everything.jpg",
                        "room_image_after": "/static/img/RoomPracticeTest/KidsRoom/kids_room_empty.jpg",
                        "stimuli": [
                            [
                                {
                                    "url": "/static/img/RoomPracticeTest/KidsRoom/Set1/backpack_2.png",
                                    "is_correct": false,
                                    "background_color": "#FFFFFF"
                                },
                                {
                                    "url": "/static/img/RoomPracticeTest/KidsRoom/Set1/backpack_3.jpg",
                                    "is_correct": false,
                                    "background_color": "#FFFFFF"
                                },
                                {
                                    "url": "/static/img/RoomPracticeTest/KidsRoom/Set1/backpack_1_correct.png",
                                    "is_correct": true,
                                    "background_color": "#FFFFFF"
                                }
                            ],
                            [
                                {
                                    "url": "/static/img/RoomPracticeTest/KidsRoom/Set2/soccer_ball2.png",
                                    "is_correct": false,
                                    "background_color": "#FFFFFF"
                                },
                                {
                                    "url": "/static/img/RoomPracticeTest/KidsRoom/Set2/Soccer_ball3.png",
                                    "is_correct": false,
                                    "background_color": "#FFFFFF"
                                },
                                {
                                    "url": "/static/img/RoomPracticeTest/KidsRoom/Set2/soccer_ball_correct.png",
                                    "is_correct": true,
                                    "background_color": "#FFFFFF"
                                }
                            ],
                            [
                                {
                                    "url": "/static/img/RoomPracticeTest/KidsRoom/Set3/teddy_bear_1_correct.png",
                                    "is_correct": true,
                                    "background_color": "#FFFFFF"
                                },
                                {
                                    "url": "/static/img/RoomPracticeTest/KidsRoom/Set3/teddy_bear3.png",
                                    "is_correct": false,
                                    "background_color": "#FFFFFF"
                                },
                                {
                                    "url": "/static/img/RoomPracticeTest/KidsRoom/Set3/teddy_bear2.png",
                                    "is_correct": false,
                                    "background_color": "#FFFFFF"
                                }
                            ]
                        ],
                        "target_image": "/static/img/RoomPracticeTest/KidsRoom/backpack_1_correct.png",
                        "target_image_background_color": "#FFFFFF",
                        "target_image_width": 180,
                        "target_x_coordinate": 273,
                        "target_y_coordinate": 638
                    },
                ],
                mock_delayed_data: [
                      {
                          "id": 43,
                          "name": "DQ-2",
                          "stimuli": [
                              [
                                  {
                                      "url": "/static/img/RoomPracticeTest/Kitchen/kitchen_empty.jpeg",
                                      "is_correct": false
                                  },
                                  {
                                      "url": "/static/img/RoomPracticeTest/KidsRoom/kids_room_empty.jpg",
                                      "is_correct": true
                                  },
                                  {
                                      "url": "/static/img/RoomPracticeTest/Library/library.jpg",
                                      "is_correct": false
                                  }
                              ]
                          ],
                          "target_image": "/static/img/RoomPracticeTest/KidsRoom/backpack_1_correct.png",
                          "target_image_background_color": null,
                          "target_image_width": 180,
                          "target_x_coordinate": 273,
                          "target_y_coordinate": 638,
                      },
                ],
              }
          },
          methods: {
            setStep: function(value) {
              this.step = value
            },
            incrementStep: function (event) {
              this.step++;
              // There are only 2 steps defined in the template, so step 3 is when the Room Test is complete
              if (this.step >= 5) {
                this.complete = true;
                this.$emit('complete');
              }
            },
            decrementStep: function (event) {
              this.step--;
            },
          }
    }
</script>