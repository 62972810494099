<template>
    <div>
        <template v-if="!display_bottom_button">
          <RightAlignButton :button_text="button_text" :click_callback="submission"></RightAlignButton>
        </template>
        <BasicError :error="error" :error_msg="error_msg"></BasicError>
        <div class="justify-content-center text-center my-2">
            <p>{{ gettext(question_text) }}</p>
            <div class="my-2" v-if="question_img">
                <BasicStimuliImage :image_url="question_img" :background_color="question_background_color"></BasicStimuliImage>
            </div>
        </div>

        <div class="row justify-content-center my-2" id="image-selection">
            <ImageSelectionItem v-for="(item, index) in question_list"
                                  v-bind:image_url="item.url"
                                  v-bind:image_index="index"
                                  v-bind:value="selected_image"
                                  v-bind:item_classname="item_classname"
                                  v-model="selected_image"
                                  :has_background="has_background"
                                  :background_color="has_background ? item.background_color : ''"
                                  v-on:input="selectionChange"
                                  v-bind:key="index">
            </ImageSelectionItem>
        </div>
      <template v-if="display_bottom_button">
          <RightAlignButton :button_text="button_text" :click_callback="submission"></RightAlignButton>
        </template>
    </div>
</template>

<script>
    import RightAlignButton from "./RightAlignButton";
    import ImageSelectionItem from "./ImageSelectionItem";
    import BasicError from "./BasicError";

    import timing from "../timing";
    import BasicStimuliImage from "./BasicStimuliImage";

    export default {
        name: "ImageSelectionList",
        mixins: [timing],
        components: {BasicStimuliImage, BasicError, RightAlignButton, ImageSelectionItem},
        props: ['question_list', "question_text", "button_text", "question_img", "item_classname", "display_bottom_button", "has_background", "question_background_color"],
        mounted: function() {
            // Record start time once component is mounted to the DOM.
            this.recordStartTime();
        },
        data: function() {
            return {
                time_start: 0,
                time_end: 0,
                selection_time: 0,
                submission_time: 0,
                selected_image: null,
                selection_count: 0,
                error: false,
                error_msg: ""
            }
        },
        methods: {
            selectionChange: function(event) {
                this.recordEndTime();
                this.error = false;
                this.selection_time = this.getTimeDiff();
                this.selection_count++;
            },

            submission: function(event) {
                if(this.selected_image !== null) {
                    this.recordEndTime();
                    this.submission_time = this.getTimeDiff() - this.selection_time;
                    // User has made a selection and is submitting, emit an event with the values to the parent.
                    this.$emit("submission", this.selected_image, this.selection_count, this.selection_time, this.submission_time)
                }
                else {
                    this.error = true;
                    this.error_msg = "Please select one of the options below"
                }
            }
        }
    }
</script>

<style scoped>

</style>