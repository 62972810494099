<template>
    <div>
        <RightAlignButton :button_text="'OK'" :click_callback="submission"></RightAlignButton>
        <BasicError :error="error" :error_msg="error_msg"></BasicError>
        <div class="canvas-container my-2" id="canvas-container">
            <canvas id="canvas" v-on:mousedown="canvasMouseDown" v-on:mouseup="canvasMouseUp" v-on:mousemove="canvasMouseMove"></canvas>
        </div>
    </div>
</template>

<script>
    import RightAlignButton from "./RightAlignButton";
    import timing from "../timing";
    import BasicError from "./BasicError";

    export default {
        name: "ImagePlacement",
        mixins: [timing],
        components: {BasicError, RightAlignButton},
        props: {
            after_image_url: {
                type: String,
                required: true,
            },
            place_image_url: {
                type: String,
                required: true,
            },
            place_image_width: {
                type: Number,
                required: true,
            },
            display_correct: {
                type: Boolean,
                required: false,
                default: false,
            },
            target_coords: {
                type: Object,
                required: true
            }
        },
        data: function() {
            return {
                time_start: 0,
                time_end: 0,
                place_x_coord: -1,
                place_y_coord: -1,
                placement_timing: 0,
                submission_timing: 0,
                error: false,
                error_msg: "",
                placed_correct: null,
                placement_distance: -1,
                max_ratio: 0,
                image_ratio: 0,
                scale_up: 1,
                scale_down: 1,
                mouse_hold: false,
            }
        },
        mounted: function() {
            this.$nextTick(function() {
                this.drawCanvas();
                this.recordStartTime();
            })

        },

        methods: {
            submission: function(event) {
                // Verify the user placed the image at least once.
                if(this.placement_timing > 0) {
                    this.recordEndTime();
                    this.submission_timing = this.getTimeDiff()-this.placement_timing;
                    this.checkPlacement();
                    this.$emit("submission", this.submission_timing, this.placed_correct, this.placement_distance);
                }
                else {
                    this.error = true;
                    // Add gettext here so that it is recognized when running makemessages
                    this.error_msg = this.gettext("Please click where you believe the object was located");
                }
            },

            drawCanvas: function(event, x=-1, y=-1) {
                let canv = document.getElementById('canvas');
                let ctx = canv.getContext('2d');
                // Format an object of image sources for preloading (ensure draw order consistency)
                let sources = {back_img: this.after_image_url, target_img: this.place_image_url};
                this.loadImages(sources, (images) => {
                    // Safari not reading clientWidth properly unless canvas exceeds size of container on smaller screens
                    // Set the canvas-width/height to be large initially so we can overwrite it correctly later
                    canv.height = 2000;
                    canv.width= 2000;
                    // Set the canvas height and width to be the background image height/width
                    let docWidth = document.getElementById('canvas-container').clientWidth;
                    let docHeight = document.getElementById('canvas-container').clientHeight;
                    this.max_ratio = docWidth / docHeight;
                    this.image_ratio = images.back_img.width / images.back_img.height;
                    canv.height = docWidth < images.back_img.width ? (docWidth / this.image_ratio) : images.back_img.height;
                    canv.width = docWidth < images.back_img.width ? docWidth : images.back_img.width;
                    this.scale_up = images.back_img.width / canv.width;
                    this.scale_down = canv.width / images.back_img.width;
                    // draw the background image first
                    ctx.drawImage(images.back_img, 0, 0, canv.width, canv.height);

                    // If we have an x and y coordinate that are > 0, draw the target_img
                    if(x >=0 && y >=0) {
                        let place_ratio = images.target_img.width / images.target_img.height;
                        let target_width = this.place_image_width * this.scale_down;
                        let target_height = Math.floor(this.place_image_width / place_ratio) * this.scale_down;
                        ctx.drawImage(images.target_img,x-(target_width/2), y-(target_height/2), target_width, target_height);
                    }

                    if(this.display_correct) {
                        this.drawCircle(this.target_coords.target_x * this.scale_down, this.target_coords.target_y * this.scale_down, this.target_coords.correct_radius * this.scale_down);
                    }

                });
            },

            updateCanvas: function(event) {
                this.recordEndTime();
                let canv = document.getElementById('canvas');
                let rect = canv.getBoundingClientRect();
                this.error = false;
                this.placement_timing = this.getTimeDiff();
                this.place_x_coord = event.clientX - rect.left;
                this.place_y_coord = event.clientY - rect.top;
                this.drawCanvas(event, this.place_x_coord, this.place_y_coord);
                this.$emit('getcoords', Math.floor(this.place_x_coord * this.scale_up), Math.floor(this.place_y_coord * this.scale_up), this.placement_timing)
            },

            canvasMouseDown: function(event) {
                this.updateCanvas(event);
                this.mouse_hold = true;
            },

            canvasMouseUp: function(event) {
                if(this.mouse_hold === true) {
                    this.updateCanvas(event)
                    this.mouse_hold = false
                }
            },

            canvasMouseMove: function(event) {
                if(this.mouse_hold === true) {
                    this.updateCanvas(event)
                }
            },

            drawCircle: function(x, y, radius) {
                let canv = document.getElementById('canvas');
                let ctx = canv.getContext('2d');
                ctx.beginPath();
                ctx.arc(x,y,radius,0, 2 * Math.PI);
                ctx.lineWidth = 1;
                ctx.strokeStyle = 'rgb(7,252,3)';
                ctx.stroke();
            },

            checkPlacement: function() {
                let distanceX = Math.pow((Math.floor(this.place_x_coord * this.scale_up) - this.target_coords.target_x), 2);
                let distanceY = Math.pow((Math.floor(this.place_y_coord * this.scale_up) - this.target_coords.target_y), 2);
                let distanceSquared = distanceX + distanceY;
                let radiusSquared = Math.pow(this.target_coords.correct_radius, 2);
                // https://math.stackexchange.com/questions/198764/how-to-know-if-a-point-is-inside-a-circle#:~:text=If%20you%20have%20the%20equation,lies%20outside%20of%20the%20circle.
                // If d^2 > r^2 then point is outside circle
                // if d^2 < r^2 then point is inside circle
                // if d^2 == r^2 then point is on circle
                // For this case we will say that being on the circle is correct
                this.placed_correct = (distanceSquared <= radiusSquared);
                // Round distance to two decimal places.
                this.placement_distance = Math.round((Math.sqrt(distanceSquared) + Number.EPSILON) * 100) / 100
            },

            loadImages: function(sources, callback) {
                let loaded_images = {};
                let loaded_image_cnt = 0;
                // Get the amount of images to preload
                let numImages = Object.keys(sources).length;
                for(let src in sources) {
                    // Add new images to the object
                    loaded_images[src] = new Image();
                    loaded_images[src].onload = function () {
                        // When an image is finished loading, check if the count of preloaded images is >= to the number of images expected to preload
                        if(++loaded_image_cnt >= numImages) {
                            // Images are preloaded now, perform callback
                            callback(loaded_images);
                        }
                    };
                    // Set the source of the images after the onload as per MDN docs.
                    loaded_images[src].src = sources[src];
                }
            }
        }
    }
</script>

<style scoped>

</style>