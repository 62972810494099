export default {
    data: function () {
        return {
            time_start: 0,
            time_end: 0,
        }
    },

    mounted: function() {
        this.recordStartTime();
    },

    methods: {
        recordStartTime: function(event) {
                // We can get more precision by using performance.now() but that costs performance (roughly 50% slower)
                this.time_start = Date.now()
            },

            recordEndTime: function(event) {
                this.time_end = Date.now()
            },

            getTimeDiff: function(event) {
                let value = this.time_end - this.time_start;
                // If time_end is < time_start, return 0 as the process was not completed or system clock was changed
                return value > 0 ? value: 0;
            },

    }//End Methods
}//End export

