<template>
    <div :class="item_classname === 'img-stimuli-delayed-phase' ? 'col-lg-12': item_classname === 'img-stimuli-sm' ? 'col-sm-4' : 'col-lg-4' + ' col-md-12 justify-content-center '">
        <label v-bind:for="'image-selection-' + image_index" :style="has_background ? `background-color: ${background_color}` : ''">
            <input class="d-none" type="radio" :id="'image-selection-' + image_index" name="selected_image" :value="image_index" v-model="value" v-on:input="$emit('input', image_index)">
            <BasicStimuliImage :image_url="image_url" :custom_classname="item_classname"></BasicStimuliImage>
        </label>

    </div>
</template>

<script>
    import BasicStimuliImage from "./BasicStimuliImage";
    export default {
        name: "ImageSelectionItem",
        components: {BasicStimuliImage},
        // Our input returns the value of 'image_index' prop to the parent
        // V-model compares the input's value attribute (in this case 'image_index') to the prop value ('value')
        // We bind the 'value' prop in the parent to be the last item that was selected
        // https://vuejs.org/v2/guide/forms.html#Radio-1
        props: {
            image_index: Number,
            image_url: String,
            value: Number,
            item_classname: String,
            has_background: Boolean,
            background_color: String,
        },
    }
</script>

<style scoped>
    label {
        cursor: pointer;
    }
    input[type="radio"] {
        visibility: hidden;
    }
    input[type="radio"]:checked+div {
        outline: 5px solid red;
    }

</style>