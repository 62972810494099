<template>
  <div>
    <div class="d-flex justify-content-start align-items-center position-fixed">
      <div class="chip chip-orange d-flex">
          <p class="m-1">{{gettext("Practice")}}</p>
      </div>
    </div>
    <div class="p-sm-0 p-lg-3">

        <template v-if="step === 0">
            <BasicInstructions v-bind:instruction_text="gettext('In this test, you will see pictures of faces and their names.')"
                               :second_text="gettext('Let\'s practice first.')"
                               :mobile_text="gettext('Please rotate your phone to landscape mode for the best experience. You may zoom in and out by using two fingers on the screen.')"
                               :mobile_text_special="gettext('Make sure you scroll down to see all question options!')"
                               :bold_text="gettext('Do not use the back button as it will restart the test.')"
                               v-on:next="incrementStep"
                               :left_text="is_mobile"
                               :display_bottom_button="true">
            </BasicInstructions>
        </template>
        <template v-else-if="step === 1 ">
          <FnameLearningPhase :display_countdown_bar="display_countdown_bar" :is_mobile="is_mobile" :displayDuration="displayDuration" :questions="mock_data" v-on:complete="incrementStep"></FnameLearningPhase>
        </template>
        <template v-else-if="step === 2 ">
            <FnameRecognitionPhase :is_mobile="is_mobile" :questions="mock_data" v-on:complete="incrementStep"></FnameRecognitionPhase>
        </template>
        <template v-else-if="step === 3 ">
            <FNameInitialPhase :is_mobile="is_mobile" :questions="mock_data" v-on:complete="incrementStep"></FNameInitialPhase>
        </template>
        <template v-else-if="step === 4 ">
            <FnameMatchingPhase :is_mobile="is_mobile" :questions="mock_data" v-on:complete="incrementStep"></FnameMatchingPhase>
        </template>
        <template v-else-if="step === 5">
          <div class="container align-content-center">
            <h2 v-if="title_text">{{gettext(title_text)}}</h2>
            <div class="my-3">
                <h2>{{gettext('End of the practice test!')}}</h2>
                <p class="mt-lg-5 mt-sm-3 max-width-750 mx-auto">{{ gettext('Would you like to retake the practice test?') }}</p>
                <div class="d-flex justify-content-center">
                  <div>
                    <button class="btn btn-lg btn-primary mx-2" v-on:click="setStep(0)">{{gettext('Yes')}}</button>
                    <button class="btn btn-lg btn-primary mx-2" v-on:click="incrementStep">{{gettext('No')}}</button>
                  </div>
                </div>
            </div>
          </div>
        </template>
    </div>
  </div>
</template>

<script>
    import BasicInstructions from "@/components/BasicInstructions";
    import FnameLearningPhase from "@/components/fname/learning/FNameLearningPhase";
    import FnameRecognitionPhase from "@/components/fname/recognition/FNameRecognitionPhase";
    import FnameMatchingPhase from "@/components/fname/matching/FNameMatchingPhase";
    import FNameInitialPhase from "@/components/fname/initials/FNameInitialPhase";
    export default {
        components: {FNameInitialPhase, FnameMatchingPhase, FnameRecognitionPhase, FnameLearningPhase, BasicInstructions},
        props: ["displayDuration", "is_mobile", "display_countdown_bar"],
        data: function() {
              return {
                  step: 0,
                  complete: false,
                  mock_data: [{
                    foil_images: ["/static/img/Susana_Foil1.jpg", "/static/img/Susana_Foil2.jpg"],
                    foil_names: ["Andrea", "Elizabeth"],
                    name: "practice-Q1",
                    ordering_id: -1,
                    question_id: -1,
                    target_image: "/static/img/Susana.jpg",
                    target_name: "Susana"
                  }]
              }
          },
          methods: {
            setStep: function(value) {
              this.step = value
            },
            incrementStep: function (event) {
              this.step++;
              if (this.step >= 6) {
                this.complete = true;
                this.$emit('complete');
              }
            },
            decrementStep: function (event) {
              this.step--;
            },
          }
    }
</script>