<template>
  <div class="p-lg-3 p-sm-1 px-sm-4 mx-sm-2">
        <template v-if="step === 0">
          <BasicInstructions v-bind:instruction_text="gettext('Now please select the first letter of the person\'s name.')"
                             v-on:next="incrementStep"
                             :display_bottom_button="true"
                             :left_text="is_mobile"
          ></BasicInstructions>
        </template>
        <template v-if="step === 1">
          <BasicError :error="error" :error_msg="error_msg"></BasicError>
          <div class="px-sm-4">
              <div class="justify-content-center text-center my-2 px-sm-4">
                <p class="text-md-center">{{ gettext('What is the first letter of this person\'s name?')}}</p>
              </div>
              <BasicStimuliImage :custom_classname="is_mobile ? 'img-stimuli-sm' : ''" :image_url="questions[currentQuestion].target_image" :id="`image-${questions[currentQuestion].question_id}`" :key="`fname-initial-${questions[currentQuestion].question_id}-${questions[currentQuestion].ordering_id}`"></BasicStimuliImage>
          </div>
          <div class=" mt-3 row justify-content-center align-items-center">
            <div class="mx-1" v-for="letter in ['q','w','e','r','t','y','u','i','o','p']"
                 :key="`letter-${letter}`"
            >
              <label v-bind:for="'keyboard-selection-' + letter">
                  <input class="d-none" type="radio" :id="'keyboard-selection-' + letter" name="selected_letter" :value="letter" v-model="selectedLetter" v-on:input="letterSelection">
                  <h3 class="letters p-lg-3 p-sm-2">{{letter.toUpperCase()}}</h3>
              </label>

            </div>
          </div>
          <div class="row justify-content-center align-items-center">
            <div class="mx-1" v-for="letter in ['a','s','d','f','g','h','j','k','l']"
                 :key="`letter-${letter}`"
            >
              <label v-bind:for="'keyboard-selection-' + letter">
                  <input class="d-none" type="radio" :id="'keyboard-selection-' + letter" name="selected_letter" :value="letter" v-model="selectedLetter" v-on:input="letterSelection">
                  <h3 class="letters p-lg-3 p-sm-2">{{letter.toUpperCase()}}</h3>
              </label>

            </div>
          </div>
          <div class="row justify-content-center align-items-center">
            <div class="mx-1" v-for="letter in ['z','x','c','v','b','n','m']"
                 :key="`letter-${letter}`"
            >
              <label v-bind:for="'keyboard-selection-' + letter">
                  <input class="d-none" type="radio" :id="'keyboard-selection-' + letter" name="selected_letter" :value="letter" v-model="selectedLetter" v-on:input="letterSelection">
                  <h3 class="letters p-lg-3 p-sm-2">{{letter.toUpperCase()}}</h3>
              </label>

            </div>
          </div>
          <RightAlignButton :button_text="'OK'" :click_callback="buttonCallback"></RightAlignButton>
        </template>
    </div>
</template>

<script>
import BasicInstructions from "@/components/BasicInstructions";
import BasicStimuliImage from "@/components/BasicStimuliImage";
import BasicError from "@/components/BasicError";
import timing from "@/timing";
import RightAlignButton from "@/components/RightAlignButton";
export default {
  name: "FNameInitialPhase",
  components: {RightAlignButton, BasicInstructions, BasicStimuliImage, BasicError},
  props: ["questions", "is_mobile"],
  mixins: [timing],
  mounted: function() {
    // set the name order for the first question, shuffle it
    this.nameOrder = [this.questions[0].target_name, this.questions[0].foil_names[0], this.questions[0].foil_names[1]]
    this.durstenfeldShuffle(this.nameOrder)
    this.$nextTick(function() {
      this.recordStartTime();
    })
  },
  data: function() {
    return {
      time_start: 0,
      time_end: 0,
      step:0,
      selectedResponse: null,
      currentQuestion: 0,
      currentImage: null,
      selectedLetter: null,
      selectionTime: 0,
      numberOfSelections: 0,
      complete: false,
      responses: [],
      nameOrder: [],
      submitInput: null,
      error: false,
      error_msg: "",
      averages: {},
      score: 0,
    }
  },
  methods: {
    incrementStep: function (event) {
      this.step++;
      this.recordStartTime()
    },

    incrementQuestion: function (event) {
      window.scrollTo(0,0)
      // reset the selected letter and the number of selections
      this.selectedLetter = null
      this.numberOfSelections = 0
      this.error = false
      this.error_msg = ""
      this.currentQuestion++;
      this.recordStartTime()
      // If we are out of questions, move to the next phase
      if (this.currentQuestion >= this.questions.length) {
        this.complete = true;
        this.averages = this.calculateAvgs()
        this.$emit('complete', this.responses);
        this.$emit('submission', this.createResult())
        return
      }
      // Set the name array to the next question, shuffle it
      this.nameOrder = [this.questions[this.currentQuestion].target_name, this.questions[this.currentQuestion].foil_names[0], this.questions[this.currentQuestion].foil_names[1]]
      this.durstenfeldShuffle(this.nameOrder)
    },
    // Modifies the original array
    durstenfeldShuffle: function(arr) {
      for(let i = arr.length -1; i>0; i--) {
        const j = Math.floor(Math.random() *(i+1));
        [arr[i], arr[j]] = [arr[j], arr[i]]
      }
    },

    letterSelection: function() {
      this.recordEndTime()
      this.selectionTime = this.getTimeDiff()
      this.numberOfSelections++
    },

    buttonCallback: function() {
      if(this.selectedLetter === null) {
        this.error = true
        this.error_msg = "Please select a letter"
        return
      }
      this.recordEndTime()
      this.responses.push({
        "name": this.questions[this.currentQuestion].name,
        'question_id': this.questions[this.currentQuestion].question_id,
        'response': this.selectedLetter.toLowerCase(),
        'correct': this.selectedLetter === this.questions[this.currentQuestion].target_name.toLowerCase()[0],
        'response_time': this.getTimeDiff(),
        'selection_time': this.selectionTime,
        'number_of_selections': this.numberOfSelections
      })
      this.incrementQuestion()
    },

    calculateAvgs: function() {
      let cumulative_corr_rt = 0
      let cumulative_corr_selection_time = 0
      let cumulative_incorr_selection_time = 0
      let cumulative_incorr_rt = 0
      let total_corr_count = 0
      let total_incorr_count = 0
      let cumulative_corr_selection_count = 0
      let cumulative_incorr_selection_count = 0
      this.responses.forEach((response) => {
        if(response.correct) {
          cumulative_corr_rt += response.response_time
          cumulative_corr_selection_time += response.selection_time
          cumulative_corr_selection_count += response.number_of_selections
          total_corr_count += 1

        }
        else {
          cumulative_incorr_rt += response.response_time
          cumulative_incorr_selection_time += response.selection_time
          cumulative_incorr_selection_count += response.number_of_selections
          total_incorr_count +=1
        }
      })
      let corr_denom = total_corr_count > 0
      let incorr_denom = total_incorr_count > 0
      this.score = total_corr_count
      return {
        "avg_corr_rt": corr_denom ? cumulative_corr_rt / total_corr_count : 0,
        "avg_incorr_rt": incorr_denom ? cumulative_incorr_rt / total_incorr_count : 0,
        "avg_corr_selection_time": corr_denom ? cumulative_corr_selection_time / total_corr_count : 0,
        "avg_incorr_selection_time": incorr_denom ? cumulative_incorr_selection_time / total_incorr_count : 0,
        "avg_corr_selection_count": corr_denom ? cumulative_corr_selection_count / total_corr_count : 0,
        "avg_incorr_selection_count": incorr_denom ? cumulative_incorr_selection_count / total_incorr_count : 0,
      }
    },

    createResult: function() {
      return {
        "user_results": this.responses,
        "averages": this.averages,
        "score": this.score
      }
    }

  }
}
</script>

<style scoped>
  h3 {
    border-radius: 10%;
  }
  h3.letters {
    background-color: lightgray;
  }
  label {
          cursor: pointer;
      }
  input[type="radio"] {
      visibility: hidden;
  }
  input[type="radio"]:checked+h3.letters {
      outline: 5px solid red;
  }

</style>